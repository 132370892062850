import { graphql, Link } from 'gatsby'
import React from 'react'
// import Form from 'react-jsonschema-form'
import styled from 'styled-components'
import Container from '../../components/Container'
import InPageNav from '../../components/InPageNav'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import Section from '../../components/Section'
import SEO from '../../components/SEO'

const schema = {
  type: 'object',
  properties: {
    userInfo: {
      title: 'Tell us who you are',
      type: 'object',
      required: ['firstName', 'lastName', 'email'],
      properties: {
        firstName: { type: 'string', title: 'First Name' },
        lastName: { type: 'string', title: 'Last Name' },
        email: { type: 'string', title: 'Email Address' },
      },
    },
    userReason: {
      title: 'What do you need help with?',
      type: 'object',
      required: ['reason', 'message'],
      properties: {
        reason: {
          type: 'string',
          title: 'Select the reason for contacting',
          enum: [
            'I need help',
            'I really need help',
            'Dear god, please help me!',
          ],
          enumNames: [
            'I need help',
            'I really need help',
            'Dear god, please help me!',
          ],
        },
        message: {
          type: 'string',
          title: 'Message body',
          name: 'testing',
        },
      },
    },
  },
}

const uiSchema = {
  userInfo: {
    firstName: {
      'ui:placeholder': 'First Name',
    },
    lastName: {
      'ui:placeholder': 'Last Name',
    },
    email: {
      'ui:options': {
        inputType: 'email',
      },
      'ui:placeholder': 'Email',
    },
  },
  userReason: {
    reason: {
      classNames: 'select-wrapper',
      'ui:widget': 'select',
      'ui:placeholder': 'Select…',
    },
    message: {
      'ui:options': {},
      'ui:widget': 'textarea',
      'ui:placeholder': 'Tell us how we can help you…',
    },
  },
}

const log = type => console.log.bind(console, type)

const ContainerMedium = styled.div`
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;

  form {
    label {
      // Visually hidden
      position: absolute !important;
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0 !important;
      border: 0 !important;
      height: 1px !important;
      width: 1px !important;
      overflow: hidden;
    }

    input,
    select,
    textarea {
      width: 100%;
    }
  }

  #root > .form-group {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;

    fieldset {
      display: contents;
    }
  }

  #root_userReason {
    legend {
      margin-top: ${props => props.theme.typography.sectionTitleSecondary.mtSm};
    }
  }

  .btn--submit {
    margin-top: 20px;
    width: 100%;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    #root > .form-group:first-child {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      #root_userInfo {
        display: contents;

        legend,
        > div:last-child {
          grid-column: span 2;
        }
      }
    }
  }
`

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
  /* background-color: ${props => props.theme.colors.nearlyWhite}; */
`

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO />

      <PageHeader
        image={data.allContentfulAsset.edges[0].node}
        title="Training"
      />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/support/contact/" activeClassName="active-page-link">
            Contact Us
          </Link>
          <Link
            to="/training/iiid-foundation/"
            activeClassName="active-page-link"
          >
            IIID Foundation
          </Link>
          <Link to="/training/product/" activeClassName="active-page-link">
            Product
          </Link>
          <Link to="/training/website/" activeClassName="active-page-link">
            Website
          </Link>
        </InPageNav>
        <Container wrap="true" afterStickyNav="true">
          <Section>
            <ContainerMedium>
              <h2 className="headline">How can we help you?</h2>
              <p className="headline--intro">
                If you can’t find the answer you’re looking for send us a
                message and someone from our ASK Team will get back to you as
                soon as possible.
              </p>
              {/* <Form
                uiSchema={uiSchema}
                schema={schema}
                onChange={log('changed')}
                onSubmit={log('submitted')}
                onError={log('errors')}
              >
                <button className="btn--submit" type="submit">
                  Send
                </button>
              </Form> */}
            </ContainerMedium>
          </Section>
        </Container>
      </AfterParallaxWrap>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          title
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Contact
